import * as React from "react"
import PropTypes from "prop-types"
import * as styles from "./leader.module.scss"

const Leader = ({ img, alt, name, position, paragraph }) => {
  return (
    <div className={styles.leaderWrapper}>
      <img src={img} alt={alt} width={168} height={168} />
      <div className={styles.name}>{name}</div>
      <div className={styles.position}>{position}</div>
      <div className={styles.horizontalLine}></div>
      <div className={styles.paragraph}>{paragraph}</div>
    </div>
  )
}

Leader.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  paragraph: PropTypes,
}
export default Leader
