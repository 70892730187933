import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutUs from "../components/about-us/AboutUs"
import OurLeaders from "../components/our-leaders/OurLeaders"
import Investors from "../components/investors/Investors"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import AboutUsMobile from "../components/about-us-mobile/AboutUsMobile"

const IndexPage = () => {
  const breakpoints = useBreakpoint()
  return (
    <Layout>
      <Seo title="Domů" />
      {/*<AboutUs />*/}
      {breakpoints.l ? <AboutUsMobile /> : <AboutUs />}
      <OurLeaders />
      <Investors />
    </Layout>
  )
}
export default IndexPage
