import * as React from "react"
import * as styles from "./ourLeaders.module.scss"
import Leader from "../leader/Leader"
import leader1 from "../../assets/image1.png.webp"
import leader2 from "../../assets/image2.png.webp"
import leader3 from "../../assets/leader3.png"

const OurLeaders = () => {
  return (
    <div className={styles.wrapperPadding}>
      <h2 className={styles.heading} id={"lide"}>
        <div className={"heading-black"}>naši</div>
        <div className={"heading-primary"}>leadři</div>
      </h2>

      <div className={styles.ourLeadersWrapper}>
        <Leader
          img={leader1}
          alt={"Obrázek leadera"}
          name={"Lukáš Novák"}
          position={"předseda představenstva"}
          paragraph={
            <div>
              Založil investiční skupinu INTEFI, která vlastní a&nbsp;řídí české
              a&nbsp;slovenské společnosti s&nbsp;obratem víc než 120 miliónů
              eur. Působí pod ním firmy z&nbsp;oblastí IT a&nbsp;vývoje
              softveru, financí a&nbsp;real estate developmentu, na&nbsp;českém
              trhu je&nbsp;spoluvlastníkem i&nbsp;knižního vydavatelství
              a&nbsp;maloobchodu. INTEFI v&nbsp;SR a&nbsp;ČR zaměstnává spolu
              více jak 1 400 lidí. Na&nbsp;Slovensku má projekty v&nbsp;hodnotě
              přesahující 50&nbsp;miliónů&nbsp;eur.
            </div>
          }
        />
        <Leader
          img={leader2}
          alt={"Obrázek leadera"}
          name={
            <div>
              Jiří Ba<span style={{ letterSpacing: "-3px" }}>ď</span>ura
            </div>
          }
          position={"člen představenstva"}
          paragraph={
            <div>
              V oblasti finančního sektoru nemovitostí se&nbsp;pohybuje
              od&nbsp;roku 2004. V&nbsp;PwC poskytoval poradenské
              a&nbsp;auditorské služby skupinám jako FINEP, Crestyl, FIM
              a&nbsp;dalším. Následně působil jako finanční manažer
              středoevropského fondu CBRE Global Investors, vlastníka nákupních
              center a&nbsp;kancelářských budov v&nbsp;ČR, Polsku
              a&nbsp;Maďarsku. Od&nbsp;roku 2019 zaštiťuje projekty
              v&nbsp;oblasti real estate pro&nbsp;skupinu INTEFI&nbsp;Capital.
            </div>
          }
        />
        <Leader
          img={leader3}
          alt={"Obrázek leadera"}
          name={"Štěpán Rázga"}
          position={"člen představenstva"}
          paragraph={
            <div>
              Kariéru v&nbsp;developmentu začal v&nbsp;roku 2008 jako finanční
              manažer Cimex Development a&nbsp;následně se stal finančním
              analytikem v&nbsp;Cimex Invest, investiční skupinv oblasti
              nemovitostí. Od&nbsp;roku 2013 působil jako provozní ředitel CPI
              Property Group, největší realitní skupině v&nbsp;CEE regionu.
              Od&nbsp;roku 2016 působí v&nbsp;rámci investiční skupiny INTEFI,
              kde zastává pozici Group CFO.
            </div>
          }
        />
      </div>
    </div>
  )
}
export default OurLeaders
