import * as React from "react"
import * as styles from "./aboutUs.module.scss"
import imgMarkerLocation from "../../assets/marker-location.svg"
import leafletKosire from "../../assets/documents/leaflet-kosire.pdf"

const AboutUs = () => {
  return (
    <div className={styles.aboutUsWrapper}>
      <div className={styles.aboutUs}>
        <div className={styles.aboutUsFlex}>
          <h2 id={"onas"}>
            <span className="heading-black">O</span>
            <span className="heading-primary ml-1">nás</span>
          </h2>
          <p className={styles.aboutUsParagraph}>
            INTEFI Real Estate je součástí lokální investiční skupiny INTEFI
            Capital. Tato skupina je aktivní v oblasti informačních technologií,
            financí, investic, nemovitostí a knižního trhu. Aktivity skupiny
            jsou soustředěny převážně v Čechách a na Slovensku. Skupina vznikla
            jako konsolidace podnikatelských aktivit zakladatele a jediného
            vlastníka skupiny Lukáše Nováka, který se v podnikatelském prostředí
            pohybuje již přes 20 let.
            <br />
            <br />
            INTEFI Real Estate buduje nemovitostní portfolio dokončených,
            funkčních projektů a projektů z oblasti developmentu. Hotové
            nemovitosti k pronájmu skupina drží za účelem generování stabilního
            výnosu z nájemného a developerské projekty za účelem realizace
            staveb převážně residenčního charakteru za účelem rozprodeje po
            dokončení projektu. Skupina roste na velikosti zejména vlivem
            úspěšných akvizic z posledních let a v tomto trendu chce i nadále
            pokračovat.
          </p>
        </div>

        <div className={styles.ourProjects} id={"projekty"}>
          <h2>
            <span className="heading-black">naše</span>
            <span className="heading-primary ml-1">projekty</span>
          </h2>
        </div>

        <div className={styles.ourProjectsLeftSide}>
          <div className="flip-card" tabIndex="0">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="card-wrapper">
                  <div className="card-spisska-img"></div>
                  <div className="info">
                    <div className="rezidencni-projekt">
                      <div>REZIDENČNÍ PROJEKT</div>
                    </div>
                    <div className="heading-black mt-3">spišská</div>
                    <hr className="hr-primary" />
                    <div className="project-location">
                      <img
                        width="11.62"
                        height="16.62px"
                        src={imgMarkerLocation}
                        alt="Obrazek polohy místa"
                      />
                      <div className="text-regular">Nitra - Chrenová</div>
                    </div>
                    <div className="text-regular mt-1">
                      Kvalitní bydlení v příjemné lokalitě
                    </div>
                  </div>
                </div>
              </div>
              <div className="flip-card-back">
                <div className={"flip-card-back-padding"}>
                  <div className="heading-black">SPIŠSKÁ</div>
                  <hr className="hr hr--black" />

                  {/*<div className="row-info">*/}
                  {/*<div className="row-heading">*/}
                  {/*  Kvalitní bydlení v příjemné lokalitě*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  <div className="row-info">
                    <div className="row-heading">Popis projektu:</div>
                    <div className="row-text">
                      5 bytových domů s 232 byty, 15 komerčními jednotkami a 172
                      vnitřních parkovacích stání
                    </div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">
                      Prodejní plocha bytů v m<sup>2</sup>:
                    </div>
                    <div className="row-text">13,919</div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">Budoucí hodnota projektu:</div>
                    <div className="row-text">29,950,000.00 €</div>
                  </div>
                  <div className="row-text--center">
                    Právoplatné územní rozhodnutí, podání dokumentace ke
                    stavebnímu povolení.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flip-card" tabIndex="0">
            <div className="flip-card-inner turcianska-card">
              <div className="flip-card-front">
                <hr className="hr-primary--full" />
                <div className="info">
                  <div className="rezidencni-projekt">
                    <div className="subheading-black mt-2 mb-1">
                      Rezidenční projekt
                    </div>
                  </div>
                  <div className="heading-black">TURČIANSKA</div>
                  <hr className="hr-primary mt-1 mb-1" />
                  <div className="project-location mb-1">
                    <img
                      width="11.62"
                      height="16.62px"
                      src={imgMarkerLocation}
                      alt="Obrazek polohy místa"
                    />
                    <div className="text-regular">Bratislava - Ružinov</div>
                  </div>
                  <div className="text-regular">
                    Bytový dům v tradiční rezidenční lokalitě
                  </div>
                </div>
                <div className="card-turcianska-wrapper mt-15">
                  <div className="card-turcianska-img--left"></div>
                  <div className="card-turcianska-img--right"></div>
                </div>
              </div>
              <div className="flip-card-back">
                <div className={"flip-card-back-padding"}>
                  <div className="heading-black">TURČIANSKA</div>
                  <hr className="hr hr--black" />

                  {/*<div className="row-info">*/}
                  {/*  <div className="row-heading">*/}
                  {/*    Bytový dům v tradiční rezidenční lokalitě*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="row-info">
                    <div className="row-heading">Popis projektu:</div>
                    <div className="row-text">
                      Rekonstrukce rodinného dvojdomu s 6 byty
                    </div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">
                      Prodejní plocha bytů v m<sup>2</sup>:
                    </div>
                    <div className="row-text">842</div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">Budoucí hodnota projektu:</div>
                    <div className="row-text">1,600,000.00 €</div>
                  </div>
                  <div className="row-text--center">
                    Projekční práce hotovy, příprava podkladů pro územní
                    rozhodnutí
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-card kosire" tabIndex="0">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <hr className="hr-primary--full" />
                <div className="info">
                  <div className="rezidencni-projekt">
                    <div className="subheading-black mt-2 mb-1">
                      Luxusní byty k v klidné čtvrti
                    </div>
                  </div>
                  <div className="heading-black">Praha Košíře</div>
                  <hr className="hr-primary mt-1 mb-1" />
                  <div className="project-location mb-1">
                    <img
                      width="11.62"
                      height="16.62px"
                      src={imgMarkerLocation}
                      alt="Obrazek polohy místa"
                    />
                    <div className="text-regular">Rezidenční čtvrť Praha - Košíře</div>
                  </div>
                  <div className="text-regular info__intro">
                    Přestavba velké rodinné vily na tři luxusní byty o velikosti 110 m2 a jeden ateliér 80 m2
                  </div>
                </div>
                <div className="card-kosire-wrapper mt-15">
                  <div className="card-kosire-img"></div>
                </div>
              </div>
              <div className="flip-card-back">
                <div className={"flip-card-back-padding"}>
                  <div className="heading-black">Praha Košíře</div>
                  <hr className="hr hr--black" />

                  {/*<div className="row-info">*/}
                  {/*  <div className="row-heading">*/}
                  {/*    Bytový dům v tradiční rezidenční lokalitě*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="row-info">
                    <div className="row-heading">Popis projektu:</div>
                    <div className="row-text">
                      Přestavba velké rodinné vily na tři luxusní byty o velikosti 110m2 a jeden ateliér o rozloze 80m2.
                      Vila se nachází ve východní části přírodního parku Košíře Motol. Vyvýšený svah poskytuje nádherný výhled na Žižkovskou věž, Národní divadlo, Letnou a Strahov.
                    </div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">Rekonstrukce:</div>
                    <div className="row-text">
                      Velkoryse pojatá rekonstrukce zahrnuje bazén, výtah a parkování pro šest aut, zaručuje atraktivitu projektu a bezesporu vzbudí zájem mezi movitými klienty.
                    </div>
                  </div>
                  <div className="row-text--center mb-1">
                    <span aria-hidden="true">Leták Košíře ke stažení</span> <a href={leafletKosire} target="_blank" className="card-link"><span className="sr-only">Leták Košíře ke stažení</span>zde</a>
                  </div>
                  <div className="row-text--center">
                    Pro více informací nás kontaktuje na <a href="mailto:intefire@intefi.cz" className="card-link">intefire@intefi.cz</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

         {/*  <div className="card-preparing-wrapper">
            <div className="heading-black">připravujeme</div>
            <div className="horizontal-line"></div>
            <div className={"card-preparation-paragraph"}>
              kancelářská nemovitost na CZ trhu o velikosti cca 8 tis. m2
              kancelářských ploch
            </div>
            <div className="horizontal-line--large"></div>
          </div> */}
        </div>
      </div>

      <div className={styles.realEstateWrapper}>
        <div className={styles.realEstateText}>
          <div className="heading-black">Dáváme</div>
          <div className="heading-black">lokalitám</div>
          <div className="heading-primary no-wrap">smysl</div>
        </div>

        <div className={styles.ourProjectsRightSide}>
          <div className="flip-card" tabIndex="0">
            <div className="flip-card-inner cassovar-card">
              <div className="flip-card-front">
                <div className="card-wrapper-cassovar">
                  <div className={"vertical-line-primary-wrapper"}>
                    <div className={"vertical-line-primary"}></div>
                  </div>
                  <div className={"card-cassovar-img-wrapper"}>
                    <div className="card-cassovar-img">
                      <div className={"cassovar-info"}>
                        <div className="subheading-black mt-1 mb-1">
                          Kancelářské prostory
                        </div>
                        <div className="heading-black">CASSOVAR</div>
                        <div className="horizontal-line-primary mt-1 mb-1" />
                        <div className="project-location mb-1">
                          <img
                            width="11.62"
                            height="16.62px"
                            src={imgMarkerLocation}
                            alt="Obrazek polohy místa"
                          />
                          <div className="text-regular">Košice - centrum</div>
                        </div>
                        <div className="text-regular">
                          Místo pro IT odborníky
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"project-info-wrapper"}>
                  <div></div>
                  <div className={"horizontal-line-primary"} />
                </div>
              </div>
              <div className="flip-card-back">
                <div className={"flip-card-back-padding"}>
                  <div className="heading-black">CASSOVAR</div>
                  <hr className="hr hr--black" />

                  {/*<div className="row-info">*/}
                  {/*  <div className="row-heading">Místo pro IT odborníky</div>*/}
                  {/*</div>*/}
                  <div className="row-info">
                    <div className="row-heading">
                      Hrubá nájemní plocha v m<sup>2</sup>: <br /> (office /
                      retail)
                    </div>
                    <div className="row-text">13 510 / 6 959</div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">
                      Obsazenost (office / retail):
                    </div>
                    <div className="row-text">100 % / 71%</div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">
                      Reálná hodnota nemovitosti:
                    </div>
                    <div className="row-text">26,300,000 €</div>
                  </div>
                  <div className="row-info">
                    <div className="row-text--center">
                      Kancelářské prostory pro IT odborníky, nákupní galerie a
                      zdravotnícká zařízení
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flip-card" tabIndex="0">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="card-wrapper-duet">
                  <div className={"row-first"}>
                    <div className="card-duet-img-1"></div>
                    <div className="card-duet-img-2"></div>
                  </div>
                  <div className={"row-second"}>
                    <div className={"project-detail-wrapper"}>
                      <div className={"hr-primary--full"}></div>
                      <div className={"project-info-padding"}>
                        <div className="subheading-black mt-2  mb-1">
                          KANCELÁŘSKÉ PROSTORY
                        </div>
                        <div className="heading-black">DUETT II</div>
                        <hr className="hr-primary mt-1 mb-1" />
                        <div className="project-location mb-1">
                          <img
                            width="11.62"
                            height="16.62px"
                            src={imgMarkerLocation}
                            alt="Obrazek polohy místa"
                          />
                          <div className="text-regular">Košice - centrum</div>
                        </div>
                        <div className="text-regular text-left">
                          Moderní kanceláře v centu města
                        </div>
                      </div>
                    </div>
                    <div className="card-duet-img-3"></div>
                  </div>
                </div>
              </div>
              <div className="flip-card-back">
                <div className={"flip-card-back-padding"}>
                  <div className="heading-black">DUETT II</div>
                  <hr className="hr hr--black" />

                  {/*<div className="row-info">*/}
                  {/*  <div className="row-heading">*/}
                  {/*    Moderní kanceláře v centu města*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="row-info">
                    <div className="row-heading">
                      Hrubá nájemní plocha v m<sup>2</sup>: <br /> (office /
                      retail)
                    </div>
                    <div className="row-text">311</div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">
                      Obsazenost (office / retail):
                    </div>
                    <div className="row-text">100%</div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">
                      Reálná hodnota nemovitosti:
                    </div>
                    <div className="row-text">730,000 €</div>
                  </div>
                  <div className="row-text--center">
                    Nemovitost s dlouhodobým nájemníkem
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flip-card patronka" tabIndex="0">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="card-wrapper-patronovka">
                  <div className={"content-wrapper"}>
                    <div className={"project-detail-wrapper"}>
                      <div className={"hr-primary--full"}></div>
                      <div className={"project-info-padding"}>
                        <div className="subheading-black mt-2  mb-1">
                          Rezidenční projekt
                        </div>
                        <div className="heading-black">Patrónka</div>
                        <hr className="hr-primary mt-1 mb-1" />
                        <div className="project-location mb-1">
                          <img
                            width="11.62"
                            height="16.62px"
                            src={imgMarkerLocation}
                            alt="Obrazek polohy místa"
                          />
                          <div className="text-regular">
                            Bratislava - Staré Město
                          </div>
                        </div>
                        <div className="text-regular text-left">
                          Rodinný dům na okraji širšího centra
                        </div>
                      </div>
                      <div className="card-patronka-img-2"></div>
                    </div>
                    <div className="card-patronka-img-1"></div>
                  </div>
                </div>
              </div>
              <div className="flip-card-back">
                <div className={"flip-card-back-padding"}>
                  <div className="heading-black">Patrónka</div>
                  <hr className="hr hr--black" />

                  {/*<div className="row-info">*/}
                  {/*  <div className="row-heading">*/}
                  {/*    Rodinný dům na okraji širšího centra*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="row-info">
                    <div className="row-heading">Popis projektu:</div>
                    <div className="row-text">
                      Rekonstrukce rodinného domu se zahradou
                    </div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">
                      Prodejní plocha bytů v m<sup>2</sup>:
                    </div>
                    <div className="row-text">300</div>
                  </div>
                  <div className="row-info">
                    <div className="row-heading">Budoucí hodnota projektu:</div>
                    <div className="row-text">700,000.00 €</div>
                  </div>
                  <div className="row-text--center">
                    Projekční práce v přípravě
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AboutUs
