// extracted by mini-css-extract-plugin
export var aboutUs = "aboutUs-module--aboutUs--wU7OF";
export var aboutUsFlex = "aboutUs-module--aboutUsFlex--cxiHo";
export var aboutUsParagraph = "aboutUs-module--aboutUsParagraph--i2ED0";
export var aboutUsWrapper = "aboutUs-module--aboutUsWrapper--4PbFt";
export var ourProjects = "aboutUs-module--ourProjects--MGL00";
export var ourProjectsLeftSide = "aboutUs-module--ourProjectsLeftSide--AcHVQ";
export var ourProjectsRightSide = "aboutUs-module--ourProjectsRightSide--+8-ym";
export var realEstate = "aboutUs-module--realEstate--mxqzk";
export var realEstateText = "aboutUs-module--realEstateText--w2Did";
export var realEstateWrapper = "aboutUs-module--realEstateWrapper--Psztp";