// extracted by mini-css-extract-plugin
export var aboutUsFlex = "aboutUsMobile-module--aboutUsFlex--KtNYw";
export var aboutUsParagraph = "aboutUsMobile-module--aboutUsParagraph--QmAhg";
export var backCardTextFlex = "aboutUsMobile-module--backCardTextFlex--j5LtK";
export var cardTurcianskaWrapper = "aboutUsMobile-module--card-turcianska-wrapper--vS2wF";
export var claim = "aboutUsMobile-module--claim--ndFz1";
export var flexCol = "aboutUsMobile-module--flexCol--3uDL9";
export var mobile = "aboutUsMobile-module--mobile--p27iH";
export var textBlock = "aboutUsMobile-module--textBlock--Hd0Nh";
export var textBlockEmty = "aboutUsMobile-module--textBlockEmty--uGjHl";
export var textBlockWrapper = "aboutUsMobile-module--textBlockWrapper--RwZk+";
export var wrapper = "aboutUsMobile-module--wrapper--x5H8s";