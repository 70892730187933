import * as React from "react"
import * as styles from "./investors.module.scss"
import oportio from "../../assets/oportio.png.webp"
import intefiFinancing from "../../assets/intefi-financing_logo.svg"
import intefiPortfolio from "../../assets/intefi-portfolio_logo.svg"
import intefiPropertySupport from "../../assets/intefi-property-support_logo.svg"
import emisniPodminkyDL72 from "../../assets/documents/InTeFi_DL_72_kS_2606_Emisni-podminky.pdf"
import emisniPodminkyDL93 from "../../assets/documents/InTeFi_DL_93_kS_2610_Emisne-podmienky.pdf"
import emisniPodminkyDL85 from "../../assets/documents/InTeFi_DL_85_kS_2701_Emisne-podmienky.pdf"
import emisniPodminkyDL105 from "../../assets/documents/InTeFi_DL_105_kS_2610_Emisni-podminky.pdf"
import emisniPodminkyDL975 from "../../assets/documents/InTeFi_DL_975_kS_2703_Emisni-podminky_v2.pdf"
import emisniPodminkyDL80 from "../../assets/documents/PROPERTY_SUPPORT_DL_800_XII2027_Emisni-podminky.pdf"
import infoListDL72 from "../../assets/documents/InTeFi-dluhopisy-zjednoduseny-infolist-220629-e199339521dd5016479ed6f8c3c96ad3.pdf"
import infoListDL93 from "../../assets/documents/InTeFI_DL_93_kS2610_letak_v2.pdf"
import infoListDL85 from "../../assets/documents/InTeFI_DL_85__kS2701_letak_v1.pdf"
import infoListDL105 from "../../assets/documents/InTeFi_DL_105_kS_2610_CZ_letak_v1.pdf"
import infoListDL975 from "../../assets/documents/InTeFi_DL_975_kS_2703_CZ_letak_v2.pdf"
import infoListDL80 from "../../assets/documents/PROPERTY_SUPPORT_DL_800_XII2027_CZ_letak_v1.pdf"
import Form from "../form/Form"

const Investors = () => {
  return (
    <div className={styles.investorsWrapper}>
      <h2 className={styles.heading} id={"proInvestory"}>
        <div className={"heading-black"}>PRO</div>
        <div className={"heading-primary"}>INVESTORY</div>
      </h2>
      <div className={styles.howItWorks}>JAK TO FUNGUJE</div>
      <div className={styles.structureWrapper}>
        <div>
          <div className={styles.standardStructure}>Standartní struktura</div>
          <div className={styles.structureContent}>
            <div className={styles.arrow}>
              <div className={styles.arrowText}>Přednost při výplatě zisku</div>
            </div>
            <div className={styles.investorsContentWrapper}>
              <div className={styles.investorsMoney}>
                <div className={styles.number}>(2)</div>
                <div className={styles.text}>Vlastní prostředky investora</div>
              </div>
              <div className={styles.bankLoan}>
                <div className={styles.number}>(1)</div>
                <div className={styles.text}>Bankovní úvěr</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.standardStructure}>
            Struktura financování s využitím crowdfoundingu a dluhopisu
          </div>
          <div className={styles.structureContentSpecial}>
            <div className={styles.arrow}>
              <div className={styles.arrowText}>Přednost při výplatě zisku</div>
            </div>
            <div className={styles.investorsContentWrapper}>
              <div className={styles.investorsResources}>
                <div className={styles.number}>(3)</div>
                <div className={styles.text}>Vlastní prostředky investora</div>
              </div>
              <div className={styles.boxesWrap}>
                <div className={styles.crowdfunding}>
                  <div className={styles.number}>(2)</div>
                  <div>Crowdfunding</div>
                </div>
                <div className={styles.dluhopisy}>Dluhopisy</div>
              </div>
              <div className={styles.bankLoanSecond}>
                <div className={styles.number}>(1)</div>
                <div className={styles.text}>Bankovní úvěr</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className={styles.ourProducts}>Naše produkty</h2>
      <h3 className={styles.subheading}>Dluhopisy</h3>
      <p className={styles.perex}>
      Emitenti jsou součástí holdingové skupiny InTeFi Real Estate ovládané společností InTeFi Capital a.s. (Skupina),
      tvořené českými a slovenskými společnostmi se zaměřením na konzervativní investice do komerčních a rezidenčních nemovitostí.
      Skupina se při investicích do nemovitostí soustředí na kombinaci dvou oblastí, které se investičně doplňují,
      nakupuje již existující výnosové nemovitosti kancelářského charakteru, s vysokou obsazeností a kvalitními nájemci,
      která skupině zajišťuje trvalý finanční tok. Ve druhé se soustředí na developerskou činnost rezidenčních nemovitostí určených střední ekonomické třídě.
      Za dobu svého působení skupina vytvořilo realitní portfolio s aktivy dosahují hodnoty téměř 750 mil. korun.
      </p>
      <div className={styles.productsCardsWrap}>

        <div className="flip-card investors" tabIndex="0">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="card-wrapper dluhopisy-box">
                <div className="subheading-black">Emisní podmínky dluhopisů společnosti Property Support a.s.</div>
                <img src={intefiPropertySupport} alt="Logo Intefi Property Suppport" width={150} height={58}/>
                <div className="heading-black">Dluhopis Property Support DL 8,00% XII/2027</div>
                <div className={"horizontal-line-medium my-20"}></div>
                <div className={"dluhopisy-text"}>
                  Dluhopis s pevnou úrokovou sazbou <span className="bold">8,00% p.a.</span> v&nbsp;předpokládané celkové jmenovité hodnotě <span className="bold">23 000 000 Kč </span>
                  s emisním kurzem <span className="bold">100%</span> jmenovité hodnoty,
                  splatné nejpozději k <span className="bold">31.12.2027 </span>
                  a datem emise <span className="bold">3.9.2024</span>.
                </div>
              </div>
            </div>
            <div className="flip-card-back">
              <div className="card-wrapper">
                <div className="subheading-black">Dokumentace k dluhopisům společnosti Property Support a.s.</div>
                <div className="heading-black">
                  Dluhopis Property Support DL 8,00% XII/2027
                </div>
                <hr className="hr hr--black"/>
                <div className="download-links">
                  <a href={emisniPodminkyDL80} className="card-link download-link">Emisní podmínky dluhopisu PROPERTY SUPPORT DL 8,00% XII/2027</a>
                  <a href={infoListDL80} className="card-link download-link">Infolist dluhopisu PROPERTY SUPPORT DL 8,00% XII/2027</a>
                </div>
                <p className="row-text--center mt-auto">Pro více informací nás kontaktuje na <a href="mailto:intefire@intefi.cz"
                                                                                                className="card-link">intefire@intefi.cz</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card investors" tabIndex="0">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="card-wrapper dluhopisy-box">
                <div className="subheading-black">Emisní podmínky dluhopisů společnosti Intefi Financing, <span>a.s.</span></div>
                <img src={intefiFinancing} alt="Logo Intefi Financing" width={150} height={58}/>
                <div className="heading-black">Dluhopis InTeFi DL 9,75% III/2027</div>
                <div className={"horizontal-line-medium my-20"}></div>
                <div className={"dluhopisy-text"}>
                  Dluhopis s pevnou úrokovou sazbou <span className="bold">9,75% p.a.</span> v předpokládané celkové jmenovité hodnotě <span className="bold">10 500 000 Kč </span>
                  s emisním kurzem <span className="bold">100%</span> jmenovité hodnoty,
                  splatné nejpozději k <span className="bold">31.3.2027 </span>
                  a datem emise <span className="bold">15.2.2024</span>
                </div>
              </div>
            </div>
            <div className="flip-card-back">
              <div className="card-wrapper">
                <div className="subheading-black">Dokumentace k dluhopisům společnosti Intefi Financing, a. s.</div>
                <div className="heading-black">
                  Dluhopis InTeFi DL 9,75% III/2027
                </div>
                <hr className="hr hr--black"/>
                <div className="download-links">
                  <a href={emisniPodminkyDL975} className="card-link download-link">Emisní podmínky dluhopisu INTEFI DL 9,75% III/2027</a>
                  <a href={infoListDL975} className="card-link download-link">Infolist dluhopisu INTEFI DL 9,75% III/2027</a>
                </div>
                <p className="row-text--center mt-auto">Pro více informací nás kontaktuje na <a href="mailto:intefire@intefi.cz"
                                                                                                className="card-link">intefire@intefi.cz</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card investors" tabIndex="0">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="card-wrapper dluhopisy-box">
                <div className="subheading-black">Emisní podmínky dluhopisů společnosti Intefi Financing, <span>a.s.</span></div>
                <img src={intefiFinancing} alt="Logo Intefi Financing" width={150} height={58}/>
                <div className="heading-black">Dluhopis InTeFi DL 10,5% X/2026</div>
                <div className={"horizontal-line-medium my-20"}></div>
                <div className={"dluhopisy-text"}>
                  Dluhopis s pevnou úrokovou sazbou <span className="bold">10,5% p.a.</span> v předpokládané celkové jmenovité hodnotě <span className="bold">10 500 000 Kč </span>
                  s emisním kurzem <span className="bold">100%</span> jmenovité hodnoty,
                  splatné nejpozději k <span className="bold">31.10.2026 </span>
                  a datem emise <span className="bold">1.11.2023</span>
                </div>
              </div>
            </div>
            <div className="flip-card-back">
              <div className="card-wrapper">
                <div className="subheading-black">Dokumentace k dluhopisům společnosti Intefi Financing, a. s.</div>
                <div className="heading-black">
                  Dluhopis InTeFi DL 10,5% X/2026
                </div>
                <hr className="hr hr--black"/>
                <div className="download-links">
                  <a href={emisniPodminkyDL105} className="card-link download-link">Emisní podmínky dluhopisu INTEFI DL 10,5% X/2026</a>
                  <a href={infoListDL105} className="card-link download-link">Infolist dluhopisu INTEFI DL 10,5% X/2026</a>
                </div>
                <p className="row-text--center mt-auto">Pro více informací nás kontaktuje na <a href="mailto:intefire@intefi.cz"
                                                                                                className="card-link">intefire@intefi.cz</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card investors" tabIndex="0">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="card-wrapper dluhopisy-box">
                <div className="subheading-black">Emisní podmínky dluhopisů společnosti Intefi portfolio, <span>a.s.</span></div>
                <img src={intefiPortfolio} alt="Logo Intefi Portfolio" width={150} height={58}/>
                <div className="heading-black">Dluhopis InTeFi DL 8,5% I/2027</div>
                <div className={"horizontal-line-medium my-20"}></div>
                <div className={"dluhopisy-text"}>
                  Dluhopis s pevnou úrokovou sazbou <span className="bold">8,5% p.a. </span>
                  v předpokládané celkové jmenovité hodnotě <span className="bold">500.000 EUR </span>
                  s emisním kurzem <span className="bold">100%</span> jmenovité hodnoty
                  splatné nejpozději k <span className="bold">31.1.2027 </span>
                  a datem emise <span className="bold">1.2.2024</span>
                </div>
              </div>
            </div>
            <div className="flip-card-back">
              <div className="card-wrapper">
                <div className="subheading-black">Dokumentace k dluhopisům společnosti Intefi Portfolio, a. s.</div>
                <div className="heading-black">
                  Dluhopis InTeFi DL 8,5% I/2027
                </div>
                <hr className="hr hr--black"/>
                <div className="download-links">
                  <a href={emisniPodminkyDL85} className="card-link download-link">Emisné podmienky dlhopisu INTEFI DL 8,5% I/2027</a>
                  <a href={infoListDL85} className="card-link download-link">Infolist dlhopisu INTEFI DL 8,5% I/2027</a>
                </div>
                <p className="row-text--center mt-auto">Pro více informací nás kontaktuje na <a href="mailto:intefire@intefi.cz"
                                                                                                className="card-link">intefire@intefi.cz</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card investors" tabIndex="0">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="card-wrapper dluhopisy-box">
                <div className="subheading-black">Emisní podmínky dluhopisů společnosti Intefi portfolio, <span>a.s.</span></div>
                <img src={intefiPortfolio} alt="Logo Intefi Portfolio" width={150} height={58}/>
                <div className="heading-black">Dluhopis InTeFi DL 9,3% X/2026</div>
                <div className={"horizontal-line-medium my-20"}></div>
                <div className={"dluhopisy-text"}>
                  Dluhopis s pevnou úrokovou sazbou <span className="bold">9,3% p.a. </span>
                  v předpokládané celkové jmenovité hodnotě <span className="bold">330.000 EUR </span>
                  s emisním kurzem <span className="bold">100%</span> jmenovité hodnoty
                  splatné nejpozději k <span className="bold">31.10.2026 </span>
                  a datem emise <span className="bold">1.11.2023</span>
                </div>
              </div>
            </div>
            <div className="flip-card-back">
              <div className="card-wrapper">
                <div className="subheading-black">Dokumentace k dluhopisům společnosti Intefi Portfolio, a. s.</div>
                <div className="heading-black">
                  Dluhopis InTeFi DL 9,3% X/2026
                </div>
                <hr className="hr hr--black"/>
                <div className="download-links">
                  <a href={emisniPodminkyDL93} className="card-link download-link">Emisné podmienky dlhopisu INTEFI DL 9,3% X/2026</a>
                  <a href={infoListDL93} className="card-link download-link">Infolist dlhopisu INTEFI DL 9,3% X/2026</a>
                </div>
                <p className="row-text--center mt-auto">Pro více informací nás kontaktuje na <a href="mailto:intefire@intefi.cz"
                                                                                                className="card-link">intefire@intefi.cz</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card investors" tabIndex="0">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <div className="card-wrapper dluhopisy-box">
                <div className="subheading-black">Emisní podmínky dluhopisů společnosti Intefi Financing, <span>a.s.</span></div>
                <img src={intefiFinancing} alt="Logo Intefi Financing" width={150} height={58}/>
                <div className="heading-black">Dluhopis InTeFi DL 7,2% VI/2026</div>
                <div className={"horizontal-line-medium my-20"}></div>
                <div className={"dluhopisy-text"}>
                  Dluhopis s pevnou úrokovou sazbou <span className="bold">7,2% p.a.</span> v předpokládané
                  celkové jmenovité hodnotě <span className="bold">24 000 000 Kč</span>, s emisním kurzem <span className="bold">100% </span>
                  jmenovité hodnoty. splatné nejpozději k<span className="bold"> 30.6.2026</span>, a datem
                  emise <span className="bold">1.7.2022</span>
                </div>
              </div>
            </div>
            <div className="flip-card-back">
              <div className="card-wrapper">
                <div className="subheading-black">Dokumentace k dluhopisům společnosti Intefi Financing, a. s.</div>
                <div className="heading-black">
                  DLUHOPIS InTeFi DL 7,2% VI/2026
                </div>
                <hr className="hr hr--black"/>
                <div className="download-links">
                  <a href={emisniPodminkyDL72} className="card-link download-link">Emisní podmínky dluhopisu INTEFI DL 7,2% VI/2026</a>
                  <a href={infoListDL72} className="card-link download-link">Infolist dluhopisu INTEFI DL 7,2% VI/2026</a>
                </div>
                <p className="row-text--center mt-auto">Pro více informací nás kontaktuje na <a href="mailto:intefire@intefi.cz"
                                                                                                className="card-link">intefire@intefi.cz</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 className={styles.subheading}>Crowdfunding</h3>
      <p className={styles.perex}>
        Platforma <a href="https://www.oportio.cz/">Oportio</a> patří do skupiny InTeFi Financing. Díky platformě Oportio máte i Vy příležitost podílet se na výnosech z
        financování exkluzivních projektů a diverzifikovat tak svoje investice.
        Naše projekty jsou spolufinancované renomovanými bankami a vlastními prostředky společnosti InTeFI.
        Oportio tak dává jedinečnou a limitovanou příležitost podílet se na úvěrování nových, ale i stávajících projektů.
      </p>
      <div className={styles.productsCardsWrap}>
        <div className="flip-card investors crowdfunding" tabIndex="0">
          <div className="card-wrapper">
            <div className="subheading-black">Crowdfunding platforma</div>
            <img src={oportio} alt="Oportio" width={273} height={61}/>
            <div className="heading-black">Investujte do ověřených nemovitostí</div>
            <div className={"horizontal-line-medium"}></div>
            <div className={"dluhopisy-text"}>
              <span class="bold">Pevný úrok</span> znamená jistotu a předvídatelnost <br/>
              <span class="bold">Sami určujete</span>, do kterých projektů chcete investovat <br/>
              <span class="bold">Diverzifikace</span> zlepšuje rizikový profil celého portfolia <br/>
              Minimálně <span class="bold">1 000 Kč</span> na projekt
            </div>
            <div className={styles.linkWrap}>
              Více na
              <a
                  className="link-wrap-link"
                  href="https://oportio.cz/"
                  target={"_blank"}
              >
                www.oportio.cz
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contactFormWrapper}>
        <div className={styles.contactFormParagraph}>
          Zaujal Vás některý z našich projektů nebo některá z investičních
          příležitostí? Máte dotaze nebo chcete více informací? Neváhejte nás
          kontaktovat a někdo z našich odborníků se Vám ozve.
        </div>
        <Form/>
      </div>
    </div>
  )
}
export default Investors
