// extracted by mini-css-extract-plugin
export var arrow = "investors-module--arrow--haLr5";
export var arrowText = "investors-module--arrowText--9vzpr";
export var bankLoan = "investors-module--bankLoan--ZsHV1";
export var bankLoanSecond = "investors-module--bankLoanSecond--iOFWz";
export var boxesWrap = "investors-module--boxesWrap--BEaOh";
export var contactFormParagraph = "investors-module--contactFormParagraph--47XBW";
export var contactFormWrapper = "investors-module--contactFormWrapper--RYIG1";
export var contactsWrapper = "investors-module--contactsWrapper--bYrga";
export var crowdfunding = "investors-module--crowdfunding--uG2MD";
export var dluhopisy = "investors-module--dluhopisy--QB1g6";
export var documentsDownload = "investors-module--documentsDownload--X7KjM";
export var heading = "investors-module--heading--bNXZd";
export var howItWorks = "investors-module--howItWorks--qA1tk";
export var investorsContentWrapper = "investors-module--investorsContentWrapper--g-8b-";
export var investorsMoney = "investors-module--investorsMoney--ma8mx";
export var investorsResources = "investors-module--investorsResources--kI7ni";
export var investorsWrapper = "investors-module--investorsWrapper--0ZXe8";
export var linkWrap = "investors-module--linkWrap--hp30o";
export var number = "investors-module--number--Ka1MZ";
export var ourProducts = "investors-module--ourProducts--xLfI7";
export var perex = "investors-module--perex--8EW1h";
export var productsCardsWrap = "investors-module--productsCardsWrap--+egom";
export var standardStructure = "investors-module--standardStructure--9c4rI";
export var structureContent = "investors-module--structureContent--uC1Jv";
export var structureContentSpecial = "investors-module--structureContentSpecial--IAJW-";
export var structureWrapper = "investors-module--structureWrapper--9acy3";
export var subheading = "investors-module--subheading--v95F1";
export var text = "investors-module--text--BYQd9";
export var textWhite = "investors-module--textWhite--p+O1E";