import * as React from "react"
import * as styles from "./aboutUsMobile.module.scss"
import imgMarkerLocation from "../../assets/marker-location.svg"
import leafletKosire from "../../assets/documents/leaflet-kosire.pdf"

const AboutUsMobile = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textBlockWrapper}>
        <div className={styles.textBlock}>
          <div className="heading-black">Lokální</div>
          <div className="heading-black">skupina</div>
          <div className="heading-primary no-wrap">s Globálním</div>
          <div className={"heading-primary"}>přesahem</div>
        </div>
        <div className={styles.textBlockEmty}></div>
      </div>

      <div className={styles.aboutUsFlex}>
        <h2 id={"onas"}>
          <span className="heading-black">O</span>
          <span className="heading-primary ml-1">nás</span>
        </h2>
        <p className={styles.aboutUsParagraph}>
          INTEFI Real Estate je součástí lokální investiční skupiny INTEFI
          Capital. Tato skupina je aktivní v oblasti informačních technologií,
          financí, investic, nemovitostí a knižního trhu. Aktivity skupiny jsou
          soustředěny převážně v Čechách a na Slovensku. Skupina vznikla jako
          konsolidace podnikatelských aktivit zakladatele a jediného vlastníka
          skupiny Lukáše Nováka, který se v podnikatelském prostředí pohybuje
          již přes 20 let.
          <br />
          <br />
          INTEFI Real Estate buduje nemovitostní portfolio dokončených,
          funkčních projektů a projektů z oblasti developmentu. Hotové
          nemovitosti k pronájmu skupina drží za účelem generování stabilního
          výnosu z nájemného a developerské projekty za účelem realizace staveb
          převážně residenčního charakteru za účelem rozprodeje po dokončení
          projektu. Skupina roste na velikosti zejména vlivem úspěšných akvizic
          z posledních let a v tomto trendu chce i nadále pokračovat.
        </p>
      </div>

      <div className={styles.flexCol}>
        <div className="flip-card-mobile" tabIndex="0">
          <div className="flip-card-inner-mobile cassovar-card">
            <div className="flip-card-front-mobile">
              <div className="card-wrapper-cassovar">
                <div className={"vertical-line-primary-wrapper"}>
                  <div className={"vertical-line-primary-mobile"}></div>
                </div>
                <div className={"card-cassovar-img-wrapper"}>
                  <div className="card-cassovar-img-mobile">
                    <div className={"cassovar-info-mobile"}>
                      <div className="subheading-black-mobile mt-1 mb-1 ml-16px">
                        Kancelářské prostory
                      </div>
                      <div className="heading-black-mobile ml-15px">
                        CASSOVAR
                      </div>
                      <div className="horizontal-line-primary" />
                      <div className="project-location-mobile ml-16px">
                        <img
                          width="11.62"
                          height="16.62px"
                          src={imgMarkerLocation}
                          alt="Obrazek polohy místa"
                        />
                        <div className="text-regular">Košice - centrum</div>
                      </div>
                      <div className={styles.claim}>Místo pro IT odborníky</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"project-info-wrapper"}>
                <div></div>
                <div className={"horizontal-line-primary"} />
              </div>
            </div>
            <div className="flip-card-back-mobile">
              <div className="heading-white-mobile mt-1">CASSOVAR</div>
              <hr className="hr-white-mobile" />
              <div className={styles.backCardTextFlex}>
                {/*<div className="row-info-mobile">*/}
                {/*  <div className="row-heading-mobile">*/}
                {/*    Místo pro IT odborníky*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Hrubá nájemní plocha v m<sup>2</sup>: <br /> (office /
                    retail)
                  </div>
                  <div className="row-text-mobile">13 510 / 6 959</div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Obsazenost (office / retail):
                  </div>
                  <div className="row-text-mobile">100 % / 71%</div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Reálná hodnota nemovitosti:
                  </div>
                  <div className="row-text-mobile">26,300,000 €</div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-text--center-mobile">
                    Kancelářské prostory pro IT odborníky, nákupní galerie a
                    zdravotnícká zařízení
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card-mobile" tabIndex="0">
          <div className="flip-card-inner-mobile">
            <div className="flip-card-front-mobile">
              <div className="card-wrapper-mobile spisska">
                <div className="card-spisska-img-mobile"></div>
                <div className="info">
                  <div className="rezidencni-projekt">
                    <div>REZIDENČNÍ PROJEKT</div>
                  </div>
                  <div className="heading-black-mobile">spišská</div>
                  <hr className="hr-primary" />
                  <div className="project-location-mobile mt-0">
                    <img
                      width="11.62"
                      height="16.62px"
                      src={imgMarkerLocation}
                      alt="Obrazek polohy místa"
                    />
                    <div className="text-regular">Nitra - Chrenová </div>
                  </div>
                  <div className="text-regular mt-5px">
                    Kvalitní bydlení v příjemné lokalitě
                  </div>
                </div>
              </div>
            </div>
            <div className="flip-card-back-mobile">
              <div className="heading-white-mobile mt-1">SPIŠSKÁ</div>
              <hr className="hr-white-mobile" />
              <div className={styles.backCardTextFlex}>
                {/*<div className="row-info-mobile">*/}
                {/*  <div className="row-heading-mobile">*/}
                {/*    Kvalitní bydlení v příjemné lokalitě*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row-info-mobile">
                  <div className="row-heading-mobile no-wrap">
                    Popis projektu:
                  </div>
                  <div className="row-text-mobile">
                    5 bytových domů s 232 byty, 15 komerčními jednotkami a 172
                    vnitřních parkovacích stání
                  </div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Prodejní plocha bytů v m<sup>2</sup>:
                  </div>
                  <div className="row-text-mobile">13,919</div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Budoucí hodnota projektu:
                  </div>
                  <div className="row-text-mobile">29,950,000.00 €</div>
                </div>
                <div className="row-text--center-mobile">
                  Právoplatné územní rozhodnutí, podání dokumentace ke
                  stavebnímu povolení.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card-mobile" tabIndex="0">
          <div className="flip-card-inner-mobile cassovar-card">
            <div className="flip-card-front-mobile">
              <div className="card-wrapper-cassovar">
                <div className={"vertical-line-primary-wrapper"}>
                  <div className={"vertical-line-primary-mobile"}></div>
                </div>
                <div className={"card-cassovar-img-wrapper"}>
                  <div className="card-duet-img-mobile">
                    <div className={"cassovar-info-mobile"}>
                      <div className="subheading-black-mobile mt-1 mb-1 ml-16px">
                        KANCELÁRSKE PROSTORY
                      </div>
                      <div className="heading-black-mobile ml-15px">DUETT</div>
                      <div className="horizontal-line-primary" />
                      <div className="project-location-mobile ml-16px">
                        <img
                          width="11.62"
                          height="16.62px"
                          src={imgMarkerLocation}
                          alt="Obrazek polohy místa"
                        />
                        <div className="text-regular">Košice - centrum</div>
                      </div>
                      <div className={styles.claim}>
                        Moderní kanceláře v centu města
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"project-info-wrapper"}>
                <div></div>
                <div className={"horizontal-line-primary"} />
              </div>
            </div>
            <div className="flip-card-back-mobile">
              <div className="heading-white-mobile mt-1">DUETT II</div>
              <hr className="hr-white" />
              <div className={styles.backCardTextFlex}>
                {/*<div className="row-info-mobile">*/}
                {/*  <div className="row-heading-mobile">*/}
                {/*    Moderní kanceláře v centu města*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Hrubá nájemní plocha v m<sup>2</sup>: <br /> (office /
                    retail)
                  </div>
                  <div className="row-text-mobile">311</div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Obsazenost (office / retail):
                  </div>
                  <div className="row-text-mobile">100%</div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Reálná hodnota nemovitosti:
                  </div>
                  <div className="row-text-mobile">730,000 €</div>
                </div>
                <div className="row-text--center-mobile">
                  Nemovitost s dlouhodobým nájemníkem
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card-mobile" tabIndex="0">
          <div className="flip-card-inner-mobile">
            <div className="flip-card-front-mobile">
              <hr className="hr-primary--full" />
              <div className="info">
                <div className="rezidencni-projekt">
                  <div className="subheading-black-mobile">
                    Rezidenční projekt
                  </div>
                </div>
                <div className="heading-black-mobile">TURČIANSKA</div>
                <hr className="hr-primary" />
                <div className="project-location mb-1">
                  <img
                    width="11.62"
                    height="16.62px"
                    src={imgMarkerLocation}
                    alt="Obrazek polohy místa"
                  />
                  <div className="text-regular">Bratislava - Ružinov</div>
                </div>
                <div className="text-regular">
                  Bytový dům v tradiční rezidenční lokalitě
                </div>
              </div>
              <div className="card-turcianska-wrapper mt-5">
                <div className="card-turcianska-img--left-mobile"></div>
                <div className="card-turcianska-img--right-mobile"></div>
              </div>
            </div>
            <div className="flip-card-back-mobile">
              <div className="heading-white-mobile mt-1">TURČIANSKA</div>
              <hr className="hr-white-mobile" />
              <div className={styles.backCardTextFlex}>
                {/*<div className="row-info-mobile">*/}
                {/*  <div className="row-heading-mobile">*/}
                {/*    Bytový dům v tradiční rezidenční lokalitě*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row-info-mobile">
                  <div className="row-heading-mobile no-wrap">
                    Popis projektu:
                  </div>
                  <div className="row-text-mobile">
                    Rekonstrukce rodinného dvojdomu s 6 byty
                  </div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Prodejní plocha bytů v m<sup>2</sup>:
                  </div>
                  <div className="row-text-mobile">842</div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Budoucí hodnota projektu:
                  </div>
                  <div className="row-text-mobile">1,600,000.00 €</div>
                </div>
                <div className="row-text--center-mobile">
                  Projekční práce hotovy, příprava podkladů pro územní
                  rozhodnutí
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card-mobile" tabIndex="0">
          <div className="flip-card-inner-mobile">
            <div className="flip-card-front-mobile">
              <div className="card-wrapper-patronovka">
                <div className={"content-wrapper"}>
                  <div className={"project-detail-wrapper w-50 "}>
                    <div className={"hr-primary--full"}></div>
                    <div className={"project-info-padding"}>
                      <div className="subheading-black-mobile no-wrap mt-5px">
                        Rezidenční projekt
                      </div>
                      <div className="heading-black-mobile">Patrónka</div>
                      <hr className="hr-primary" />
                      <div className="project-location mb-1">
                        <img
                          width="11.62"
                          height="16.62px"
                          src={imgMarkerLocation}
                          alt="Obrazek polohy místa"
                        />
                        <div className="text-regular no-wrap">
                          Bratislava - Staré Město
                        </div>
                      </div>
                      <div className="text-regular">
                        Rodinný dům na okraji širšího centra
                      </div>
                    </div>
                    <div className="card-patronka-img-2-mobile"></div>
                  </div>
                  <div className="card-patronka-img-1-mobile"></div>
                </div>
              </div>
            </div>
            <div className="flip-card-back-mobile">
              <div className="heading-white-mobile mt-1">Patrónka</div>
              <hr className="hr-white-mobile" />
              <div className={styles.backCardTextFlex}>
                {/*<div className="row-info-mobile">*/}
                {/*  <div className="row-heading-mobile">*/}
                {/*    Rodinný dům na okraji širšího centra*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row-info-mobile">
                  <div className="row-heading-mobile no-wrap">
                    Popis projektu:
                  </div>
                  <div className="row-text-mobile">
                    Rekonstrukce rodinného domu se zahradou
                  </div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Prodejní plocha bytů v m<sup>2</sup>:
                  </div>
                  <div className="row-text-mobile">300</div>
                </div>
                <div className="row-info-mobile">
                  <div className="row-heading-mobile">
                    Budoucí hodnota projektu:
                  </div>
                  <div className="row-text-mobile">700,000.00 €</div>
                </div>
                <div className="row-text--center-mobile">
                  Projekční práce v přípravě
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flip-card-mobile kosire" tabIndex="0">
          <div className="flip-card-inner-mobile">
            <div className="flip-card-front-mobile">
              <hr className="hr-primary--full" />
              <div className="info">
                <div className="rezidencni-projekt">
                  <div className="subheading-black-mobile">
                    Luxusní byty v klidné čtvrti
                  </div>
                </div>
                <div className="heading-black-mobile">Praha Košíře</div>
                <hr className="hr-primary" />
                <div className="project-location mb-1">
                  <img
                    width="11.62"
                    height="16.62px"
                    src={imgMarkerLocation}
                    alt="Obrazek polohy místa"
                  />
                  <div className="text-regular">Rezidenční čtvrť Praha - Košíře</div>
                </div>
                <div className="text-regular">
                  Přestavba velké rodinné vily na tři luxusní byty o velikosti 110 m2 a jeden ateliér 80 m2
                </div>
              </div>
              <div className="card-kosire-wrapper mt-5">
                <div className="card-kosire-img--mobile"></div>
              </div>
            </div>
            <div className="flip-card-back-mobile">
              <div className="heading-white-mobile mt-1">Praha Košíře</div>
              <hr className="hr-white-mobile" />
              <div className={styles.backCardTextFlex}>
                {/*<div className="row-info-mobile">*/}
                {/*  <div className="row-heading-mobile">*/}
                {/*    Bytový dům v tradiční rezidenční lokalitě*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row-info-mobile">
                  <div className="row-heading-mobile no-wrap">
                    Popis projektu:
                  </div>
                  <div className="row-text-mobile">
                  Přestavba velké rodinné vily na tři luxusní byty o velikosti 110m2 a jeden ateliér o rozloze 80m2.
                  </div>
                </div>
                <div className="row-text--center-mobile">
                  <span aria-hidden="true">Leták Košíře ke stažení</span> <a href={leafletKosire} target="_blank" className="card-link"><span className="sr-only">Leták Košíře ke stažení</span>zde</a>
                </div>
                <div className="row-text--center-mobile">
                  Pro více informací nás kontaktuje na <a href="mailto:intefire@intefi.cz" className="card-link">intefire@intefi.cz</a>
                </div>
              </div>
            </div>
          </div>
        </div>

       {/*  <div className="card-preparing-wrapper-mobile">
          <div className="heading-black-mobile">připravujeme</div>
          <div className="horizontal-line"></div>
          <div className={"card-preparation-paragraph"}>
            kancelářská nemovitost na CZ trhu o velikosti cca 8 tis. m2
            kancelářských ploch
          </div>
          <div className="horizontal-line--large"></div>
        </div> */}
      </div>
    </div>
  )
}
export default AboutUsMobile
