import React, { useState } from "react"
import axios from "axios"
import * as styles from "./form.module.scss"

const Form = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [mail, setMail] = useState("")
  const [question, setQuestion] = useState("")
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [isValidMail, setIsValidMail] = useState(true)

  const handleSubmit = event => {
    event.preventDefault()

    const isValidEmail = mail.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
    )

    setIsValidMail(!!isValidMail)

    if (!isValidEmail) {
      return
    }

    const payload = {
      name: name,
      phone: phone,
      mail: mail,
      question: question,
    }
    setIsSendingForm(true)
    axios
      .post(
        "https://5t0nxd7eu0.execute-api.eu-central-1.amazonaws.com/shared/contactForm/send",
        payload
      )
      .then(res => {
        console.log(res)
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit} id={"kontakt"}>
      <div className={styles.heading}>kontaktujte nás</div>
      <input
        id="name"
        name="name"
        type="text"
        placeholder={"Jméno a příjmení"}
        className={styles.input}
        onChange={event => setName(event.currentTarget.value)}
      />

      <input
        id="phone"
        name="phone"
        type="number"
        placeholder={"Telefon"}
        className={styles.input}
        onChange={event => setPhone(event.currentTarget.value)}
      />

      <input
        id="mail"
        name="mail"
        type="mail"
        placeholder={"Email"}
        className={styles.input}
        onChange={event => setMail(event.currentTarget.value)}
      />
      {!isValidMail && <span>Prosím vyplňte validní email</span>}

      <textarea
        id="question"
        name="question"
        placeholder={"Dotaz"}
        className={styles.textarea}
        onChange={event => setQuestion(event.currentTarget.value)}
      />

      <button className={styles.btnPrimary} type="submit">
        Submit
      </button>
    </form>
  )
}
export default Form
